<template>
  <div class="MarBox">
    <Header></Header>
    <div class="article_content">
      <div class="wrapper">
        <!-- 标题 -->
        <div class="top">{{ name }}</div>
        <!-- 栏目对应的文章标题 -->
        <div class="bottom">
          <div class="parent">
            <div class="left" style="display: none">
              <div class="a_top">
                <h3>词条统计</h3>
              </div>
              <div class="a_l_nav">
                <ul>
                  <li @click='toArticle(item.category)' v-for="item in categories" :key='item.id'>
                    <span>{{ item.category.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="right">
              <div class="article_title">
                <div class="h3Text_Le">
                  <div class="h3Text"></div>
                  <div class="h3Text1">{{ wikiInfo.title }}</div>
                </div>
                <div><i class="el-icon-view" style="margin-right: 5px;"></i>{{ wikiInfo.views }}</div>
              </div>
              <div class="article_des" v-if="wikiInfo.description"> {{ wikiInfo.description }}</div>
              <div class="infoBox">
                <div v-html="wikiInfo.content" class="infoBox_item"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import {get, getFromData} from "@/utils/request";
import deImg from "@/assets/index/pc-b.jpg";

export default {
  data() {
    return {
      name: "",
      id: "",
      params: {
        page: 1,
        pageSize: 5,
      },
      categories: [],
      wikiInfo: ''
    };
  },
  // 监听器属性/侦听器属性
  // 监听数据类型变化
  // 监听基本数据类型使用浅监听
  // 监听引用数据类型使用深度监听
  watch: {
    "$route.query": {
      handler() {
        console.log(this.$route.query)
      },
      deep: true
    }
  },
  created() {
    // console.log(this.$route.query, 'created');
    this.id = this.$route.query.id;
    this.getWebInfo()
    this.getArticleById();
  },
  methods: {
    async getWebInfo() {
      await get("/api/webPage/getWebPageInfo").then(res => {
        if (res && res.data) {
          let backImg = res.data.backgroundImageUrl || deImg;
          this.setBgImg(backImg)
        } else {
          this.setBgImg(deImg)
        }
      }).catch(() => {
        this.setBgImg(deImg)
      })
    },
    setBgImg(img) {
      this.$nextTick(() => {
        const bodyStyle = document.body.style, // 获取body节点样式
          docEl = document.documentElement,
          docBody = document.body,
          winWidth = docEl.clientWidth || docBody.clientWidth; // 获取浏览器的宽度
        if (winWidth > 850) {
          let sty = "url(" + img + ") #b4daef no-repeat";
          bodyStyle.background = sty;
          bodyStyle.backgroundAttachment = "fixed";
        } else {
          bodyStyle.background = "#ffffff";
        }
      });
    },
    // 根据栏目id获取对应栏目文章
    async getArticleById() {
      let res = await getFromData("/api/wiki/getWikiInfo", {id: this.id});
      document.title = res.data.title
      this.wikiInfo = res.data
    },
    toArticle(item) {
      // 编程式导航跳转
      this.$router.push({
        path: "/article",
        query: item
      })
    }
  }
};
</script>
<style lang="scss">
// 体部样式
.article_content {
  // height: calc(100vh - 0px);
  background-color: #ecf6f2;
  padding: 2em 1.5em;

  .wrapper {
    width: 95%;

    .top {
      font-size: 28px;
      color: #014791;
      line-height: 20px;
    }

    .bottom {
      .parent {
        display: flex;
        min-height: 470px;

        .right {
          //width: 75%;
          width: 100%;
          background-color: #fff;
          border: 1px solid #e5e5e5;;

          .article_title {
            min-height: 40px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 95%;
            margin: 10px auto;
            font-size: 18px;

            .h3Text_Le {
              width: 95%;
              display: flex;
            }

            @media only screen and (max-width: 1000px) {
              .h3Text_Le {
                width: 80%;
              }
            }

            .h3Text {
              margin-left: 10px;
              border-left: 4px solid #be5b24;
              color: #333333;
              font-weight: bold;
            }

            .h3Text1 {
              margin-left: 9px;
              color: #333333;
              font-weight: bold;
            }
          }

          .article_des {
            padding: 15px;
            line-height: 25px;
            font-size: 16px;
            background: #fafafa;
            width: 96%;
            color: #333333;
            border-radius: 5px;
            margin: 15px auto;
          }

          .list_top {
            // display: flex;
            padding: 0 20px;
            overflow: hidden;
            background: #fafafa;
            border-left: 1px solid #e5e5e5;
            border-right: 1px solid #e5e5e5;

            span {
              line-height: 40px;
              font-size: 14px;
              color: black;
            }

            span:nth-child(1) {
              display: inline-block;
              width: 74%;
              text-align: center;
            }

            span:nth-child(2) {
              display: inline-block;
              width: 13%;
              margin: 0 1%;
              text-align: center;
            }

            span:nth-child(3) {
              display: inline-block;
              width: 10%;
              text-align: center;
            }
          }

          .infoBox {
            width: 97%;
            margin: 0 auto;
            margin-bottom: 20px;
            overflow: hidden;

            .infoBox_item {
              margin: 10px;
              font-size: 17px;
              padding: 4px;
              word-wrap: break-word;
            }
          }

          .show {
            ul {
              // padding:2em 1em;
              li {
                padding: 0 20px;
                line-height: 60px;
                border-bottom: 1px solid #ececec;
                color: black;
                font-size: 16px;

                span:nth-child(1) {
                  // height: 41px;
                  // line-height: 31px;
                  vertical-align: bottom;
                  overflow: hidden; //多余的部分隐藏
                  white-space: nowrap; //让文字强制一行显示
                  text-overflow: ellipsis; //多余的部分显示省略号
                  display: inline-block;
                  width: 74%;
                  cursor: pointer;
                  // text-align: center;
                }

                span:nth-child(2) {
                  display: inline-block;
                  width: 13%;
                  margin: 0 1%;
                  text-align: center;
                }

                span:nth-child(3) {
                  display: inline-block;
                  width: 10%;
                  text-align: center;
                }

                // span:last-child {
                //   margin-left: 20px;
                //   display: inline-block;
                // }
                span:nth-child(1):hover {
                  color: #990000;
                  text-decoration: underline;
                }
              }
            }
          }

          .page {
            text-align: center;
            margin: 20px 0;
          }
        }

        .left {
          border: 1px solid #e5e5e5;
          background-color: white;
          width: 20%;
          height: 280px;

          .a_top {
            // width: 300px;
            background-color: #fff;
            height: 55px;
            padding-left: 40px;
            background: url('../assets/yxtx/xwgk_condian.png') 20px 50% no-repeat #be5b24;
            line-height: 55px;

            h3 {
              margin: 0px;
              color: #FFFFFF;
              font-size: 16px;
              font-family: "微软雅黑";
            }
          }

          .a_l_nav {
            padding: 20px;

            ul {
              li {
                overflow: hidden;
                background: url('../assets/yxtx/xwgk_sjx.png') 10px 50% no-repeat;
                line-height: 35px;
                border-bottom: 1px solid #e5e5e5;
                padding: 0 0 0 15px;
                font-size: 16px;
                color: black;

                span {
                  cursor: pointer;
                }

                span:hover {
                  color: #990000;
                  text-decoration: underline;
                }
              }
            }
          }
        }

        @media only screen and (max-width: 1000px) {
          .left {
            width: 100%;
          }

          .right {
            width: 100%;
            margin-left: unset;

            .article_des {
              width: 90%;
              font-size: 18px;
              line-height: 30px;
            }

            .article_title {
              .h3Text {
                height: 24px;
              }
            }

            .infoBox_item {
              line-height: 34px;
            }

            .infoBox img {
              width: 88%;
            }
          }
        }
      }
    }
  }
}


/*屏幕宽度大于1000*/
@media only screen and (min-width: 1000px) {
  .MarBox {
    max-width: 580px;
    min-width: 360px;
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    position: relative;
  }
}

@media only screen and (max-width: 1000px) {
  .wrapper {
    width: 99%;
  }
  .parent {
    flex-wrap: wrap;
  }
}
</style>
